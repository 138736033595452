<template>

<div class="search">

	<div class="search-input">

		<input v-model="text" type="text" :placeholder="placeholder" class="search-input-input" v-on:keyup="onKeyDown" />

		<app-icon icon="search" class="search-input-icon" v-on:click.native="onSearchClick" />

	</div>

	<div class="search-suggestions" v-if="suggestions.length">

		<div class="search-suggestions-item" v-for="suggestion in suggestions" :key="suggestion.id" v-on:click="onSuggestionSelect(suggestion.id)">{{ suggestion.name }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['placeholder', 'default', 'onSubmit', 'apiSelect', 'apiSuggest'],

	data: function() {

		return {
			text: '',
			suggestions: []
		}

	},

	created: function() {

		this.text = this.default

	},

	methods: {

		onSuggestionSelect: function(id) {

			if (this.apiSelect) {

				this.$api.post(this.apiSelect, {
					id: id
				}).then(function() {

					this.suggestions = []
					this.text = ''

				}.bind(this))

			} else {

				this.$emit('selected', id)

			}

		},

		onSuggested: function(response) {

			this.suggestions = response.suggestions

		},

		onSearchClick: function() {

			if (this.onSubmit) {

				this.$router.push({
					name: this.onSubmit,
					query: (this.text === '') ? null : {
						search: this.text
					}
				})

			}

		},

		onKeyDown: function(e) {

			if (this.apiSuggest) {

				if (this.text) {

					this.$api.post(this.apiSuggest, {
						text: this.text
					}).then(this.onSuggested.bind(this))
					
				} else {

					this.suggestions = []

				}

			} else {
				
				if (this.onSubmit && e.key === 'Enter') {

					this.$router.push({
						name: this.onSubmit,
						query: (this.text === '') ? null : {
							search: this.text
						}
					})

				}

			}

		}

	}

}

</script>

<style scoped>

.search {
	margin: 10px 0px 10px 0px;
}

.search-input {
	color: #8896aa;
	background-color: #455160;
	text-transform: uppercase;
	font-weight: 400;
	padding: 0px 20px;
}

.search-input-icon {
	font-size: 20px;
    position: absolute;
    right: 20px;
    top: 12px;
    color: #7f8b9d;
	cursor: pointer;
}

.search-input-input {
	font-size: 16px;
	letter-spacing: 1px;
	padding: 12px 30px 12px 0px;
	font-weight: 300;
	color: #fff;
}

.search-input-input::placeholder {
	color: #7f8b9d;
}

.search-suggestions {
	padding: 5px 0px;
	background-color: #3b434e;
}

.search-suggestions-item {
	color: #fff;
	font-size: 16px;
	padding: 5px 20px;
	cursor: pointer;
}

</style>
